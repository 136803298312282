<!-- 错题集 -->
<style lang="scss" scoped>

*{
    box-sizing: border-box;
}
.strict-courses{
    @include pageTitle(0);
    height: calc(100vh - 120px);
    overflow-y: auto;
    overflow-x: hidden;
}
.page-title{
  padding: 20px 0;
}
.model{
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 20px;
    height: 350px;
    .h4{
        font-size: 14px;
        color: #AAAAAA;
        line-height: 30px;
    }
    .serverData{
      width: 100%;
      height: 200px;
      margin: 20px auto 0;
    }
    .chartyuan{
      width: 200px;
      height: 200px;
      margin: 20px auto 0;
    }
    .textNumber{
      margin-top: 20px;
      font-size: 14px;
      .text{
        color: #666666;
      }
      .number{
        color: #01A1FD;
        font-weight: bold;
        margin-left: 16px;
      }
      .iconfont{
        font-size: 14px;
        margin-right: 10px;
      }
    }
}
.dayData{
    width: 100%;
    display: inline-block;
    .item{
        width: 50%;
        text-align: center;
        float: left;
        margin-top: 30px;
        .number{
            font-size: 20px;
            font-weight: bold;
            line-height: 60px;
        }
        .p{
            font-size: 14px;

        }
    }
}
.list{
    width: 100%;
    min-height: 260px;
    margin-bottom: 24px;
    display: inline-block;
    position: relative;
    .item{
        position: relative;
        width: 400px;
        height: 100px;
        background: #FFFFFF;
        border-radius: 14px;
        margin-bottom: 24px;
        padding: 20px 20px 0 100px;
        float: left;
        margin-right: 20px;
        .course{
            position: absolute;
            width: 60px;
            height: 60px;
            border-radius: 14px;
            left: 20px;
            top: 20px;
            text-align: center;
            line-height: 60px;
            font-size: 23px;
            font-weight: bold;
            background-color: #FFA37A;
            color: #FFFFFF;
        }
        .name{
            height: 30px;
            font-size: 20px;
            font-weight: bold;
            color: #3D3D3D;
            line-height: 30px;
        }
        .number{
            height: 30px;
            font-size: 20px;
            font-weight: bold;
            color: #222222;
            line-height: 30px;
        }
        .p{
            height: 30px;
            font-size: 15px;
            font-weight: 400;
            color: #888888;
            line-height: 30px;
            margin-top: 5px;
        }
        &:hover{
            background-color: #f6efff;
            cursor: pointer;
        }
    }
}
</style>

<template>
    <section class="strict-courses">
        <div class="page-title">
          <h3>错题集</h3>
          <div class="search-form">
            
            <el-select v-if="grdeArray.length>0" v-model="grdeIndex" placeholder="请选择" @change="changeGrde">
                <el-option
                v-for="item in grdeArray"
                :key="item.grdeIndex"
                :label="item.grade_semester"
                :value="item.grdeIndex">
                </el-option>
            </el-select>
            <div v-else class="c-6">暂无年级信息</div>
          </div>
        </div>
        <div class="bigdata" v-loading="loading">
            <el-row :gutter="20">
                <el-col :span="6">
                    <div class="model">
                        <div class="h4">今日错题情况</div>
                        <!-- 今天数据 -->
                        <div class="dayData">
                            <div class="item">
                                <div class="number">{{dataInfo.stwro_tadaygraspnum}}</div>
                                <div class="p">今日掌握</div>
                            </div>
                            <div class="item">
                                <div class="number">{{dataInfo.stwro_tadaycorrectnum}}</div>
                                <div class="p">今日做对</div>
                            </div>
                            <div class="item">
                                <div class="number">{{dataInfo.stwro_tadayaddnum}}</div>
                                <div class="p">今日新增</div>
                            </div>
                            <div class="item">
                                <div class="number">{{dataInfo.stwro_tadayagainnum}}</div>
                                <div class="p">今日重做</div>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="9">
                    <div class="model">
                        <div class="h4">错题重做进度</div>
				                <div class="chartyuan" ref="performance"></div>
                        <div class="flex_around_align">
                          <div class="textNumber flex_align">
                            <div class="text">已掌握:</div>
                            <div class="number">{{dataInfo.stwro_graspnum}}</div>
                          </div>
                          <div class="textNumber flex_align"> 
                            <div class="text">剩余题:</div>
                            <div class="number">{{dataInfo.stwro_surplusnum}}</div>
                          </div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="9">
                    <div class="model">
                        <div class="h4">近7天错题集统计</div>
				                <div class="serverData" ref="serverData"></div>
                        <div class="flex_around_align">
                          <div class="textNumber flex_align">
                            <div class="iconfont" style="color:#01A1FD;">&#xe666;</div>
                            <div class="text">其他日期</div>
                          </div>
                          <div class="textNumber flex_align"> 
                            <div class="iconfont" style="color:#1DBB62;">&#xe666;</div>
                            <div class="text">今天</div>
                          </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="page-title">
          <h3>我的错题</h3>
        </div>
        <div class="list" v-loading="loading">
            <div v-for="(item,index) in dataInfo.stwro_subjectjson" :key="index">
                <div class="item" @click="goDetails(item)">
                    <div class="course">{{getfirsttext(item.sysub_name)}}</div>
                    <div class="flex_bet_align">
                        <div class="name">{{item.sysub_name}}</div>
                        <div class="number">{{item.all_count}}道</div>
                    </div>
                    <div class="flex_bet_align">
                        <div class="p flex_align">今日<span class="c-red">+{{item.dayaddnum}}</span>道错题</div>
                        <div class="p">今日掌握<span class="c-green">+{{item.dayaddgrasp}}</span> 道题</div>
                    </div>
                </div>
            </div>
            <div class="no-data--empty" v-if="$isEmpty(dataInfo.stwro_subjectjson)">
                <img src="@assets/images/no-data3.png" alt="">
                <p>暂无记录</p>
            </div>
        </div>
    </section>
</template>

<script>
import { grade_semester,stuwrongquestionscschidList } from "@api/home"
export default {
    name: 'schoolPlay',
    data() {
        return {
            //年级
            grdeArray:[],
            grdeIndex:null,
            loading:false,
            dataInfo:{
                stuser_id:0,
                stwro_graspnum:0,
                stwro_id:0,
                stwro_surplusnum:0,
                stwro_tadayaddnum:0,
                stwro_tadayagainnum:0,
                stwro_tadaycorrectnum:0,
                stwro_tadaygraspnum:0,
                stwro_subjectjson:[],
                chart:[{time:0,num:0},{time:0,num:0},{time:0,num:0},{time:0,num:0},{time:0,num:0},{time:0,num:0},{time:0,num:0}]
            },
        }
    },
    created() {
        this.grade_semester();
    },
    methods: {
       
        /** 获取年级 */
        async grade_semester() {
            let { data } = await grade_semester();
            data.forEach((item,index) => {
                item.grdeIndex=index
            });
            this.grdeArray=data
            if(data.length>0){
             this.grdeIndex=0
            }
            this.$forceUpdate();
            if(data.length>0){
                this.getData()
            }else{
                this.drawChart(0)
                this.getServerData()
            }
        },
      
        /** 获取错题主列表 */
        async getData() {
            this.loading=true
            let params = {
                sccla_id:this.grdeArray[this.grdeIndex].sccla_id,
                stwro_grade:this.grdeArray[this.grdeIndex].sccla_grade,
                stwro_semester:this.grdeArray[this.grdeIndex].sccla_semester,
            }
            let { data } = await stuwrongquestionscschidList(params);
            this.dataInfo=data
            this.loading=false
            this.drawChart(data.progress)
            this.getServerData()
            this.$forceUpdate();
        },
        // 切换年级
        changeGrde(e){
            this.getData()
        },
        //获取第一个文字返回
        getfirsttext(text){
            return text.slice(0,1)
        },
        goDetails(item){
            this.$router.push({
                name:"MISTAKES_DETAILS",
                params: {
                  id:item.sysub_id,
                  course:item.sysub_name,
                  sccla_id:this.grdeArray[this.grdeIndex].sccla_id
                }
            })
        },
        initChart() {
        },
        /** 重做进度 */
        drawChart(vals) {
          let echarts = this.$echarts.init(this.$refs.performance)
          let option = {
              title: {
                  text: vals+'%',
                  x: 'center',
                  y: 'center',
                  textStyle: {
                      fontWeight: 'normal',
                      color: '#01A1FD',
                      fontSize: '24'
                  }
              },
              color: ['rgba(1,161,253,.4)'],
              series: [{
                  type: 'pie',
                  radius: ['0', '66%'],
                  center: ['50%', '50%'],
                  hoverAnimation: false,
                  z: 0,
                  itemStyle: {
                      normal: {
                          color: '#FFFFFF',
                          label: {
                              show: false
                          },
                          labelLine: {
                              show: false
                          }
                      },
                  },
                  label: {
                      normal: {
                          position: "center",

                      }
                  },
                  data: [100],
              }, {
                  name: 'Line 1',
                  type: 'pie',
                  clockWise: true,
                  radius: ['70%', '90%'],
                  itemStyle: {
                      normal: {
                          label: {
                              show: false
                          },
                          labelLine: {
                              show: false
                          }
                      }
                  },
                  hoverAnimation: false,
                  data: [{
                      value: vals,
                      name: '01',
                      itemStyle: {
                          normal: {
                              color: { // 完成的圆环的颜色
                                  colorStops: [{
                                      offset: 1,
                                      color: '#01A1FD' // 100% 处的颜色
                                  }]
                              },
                              label: {
                                  show: false
                              },
                              labelLine: {
                                  show: false
                              }
                          }
                      }
                  }, {
                      name: '02',
                      value: 100-vals
                  }]
              }]
          };
          echarts.setOption(option)
        },
        // 柱状图
        getServerData(){
          let echarts = this.$echarts.init(this.$refs.serverData)
          // 时间显示
          let xAxisdata=[]
          // 错题数
          let series_number=[]
          this.dataInfo.chart.map((item,index)=>{
            if(index<6){
              xAxisdata.push(item.time)
              series_number.push(item.num)
            }else{
              xAxisdata.push("今")
              series_number.push(item.num)
            }
          })


          let option = {
            color: ['#3398DB'],
            tooltip: {
                trigger: 'axis',
                axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                    type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                }
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    data:xAxisdata,
                    axisTick: {
                        alignWithLabel: true
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value'
                }
            ],
            series: [
                {
                    name: '错题数',
                    type: 'bar',
                    barWidth: '60%',
                    data:series_number,
                    itemStyle: {
                      normal: {
                        color: function (params) {
                          let colorList = [
                            '#01A1FD',
                            '#01A1FD',
                            '#01A1FD',
                            '#01A1FD',
                            '#01A1FD',
                            '#01A1FD',
                            '#1DBB62'
                          ];
                          let colorItem = colorList[params.dataIndex];
                          return colorItem;
                        }
                      }
                    }
                }
            ]
          };
          
          echarts.setOption(option)
        },
      
    },
}
</script>